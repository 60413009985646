import React from 'react'
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { BsLinkedin, BsInstagram } from "react-icons/bs";


function contact() {
    return ( 
        <Layout>
            <Helmet>
              <title>Contact - Website Laten Stylen</title>
              <meta name="description" content="Aarzel niet om me een mailtje te sturen als je hulp nodig hebt." />
              <meta property="og:description" content="Aarzel niet om me een mailtje te sturen als je hulp nodig hebt." />
            </Helmet>
            <div className='container'>
              <h1>Contact</h1>
              <p>Aarzel niet om me een mailtje te sturen ( <a href="mailto:info@websitelatenstylen.nl">info@websitelatenstylen.nl</a> ) of te bellen ( <a href="tel:0643126237">06 - 431 26 237</a> ) als je hulp nodig hebt. Je kunt ook een kijkje nemen op <Link to="https://www.bartsalle.nl" target="_blank">mijn persoonlijke website</Link> om te zien wat ik nog meer voor je kan betekenen.</p>
              <p>Ik word nog steeds enthousiast van elke nieuwe uitdaging!</p>
            </div>
            <div className='container socials'><Link to="https://www.linkedin.com/in/bartsalle/" target="_blank" alt="Bezoek mijn LinkedIn profiel" title="Bezoek mijn LinkedIn profiel"><BsLinkedin size="35" /></Link> <Link to="https://www.instagram.com/websitelatenstylen/" target="_blank" alt="Bezoek mijn Instagram pagina" title="Bezoek mijn Instagram pagina"><BsInstagram size="35" /></Link></div>
        </Layout>
      )
}

export default contact